<template>
  <div id="app">
    <Hero/>
    <Main/>
    <Footer/>
  </div>
</template>

<script>
import Hero from './components/Hero.vue'
import Main from './components/Main.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Hero,
    Main,
    Footer
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.text-center{
  text-align: center;
}
.cursor-pointer{
  cursor: pointer;
}
</style>
