<template>
  <section class="section-style">
    <div class="container container-style">
      <div class="row align-items-center">
        <div class="col-6">
          <img alt="logo" src="../assets/logo.svg">
        </div>

      </div>
      <div class="row row-typer">
        <span class="slogan">We are a fully integrated strategy and creativity agency of <br>multi-skilled and curious specialists.</span>
          <!-- <div
              class="vue-typer"
              ref="theRef"
            >
              <vue-typer
                v-if="startTypers"
                :text="[
                  formatText(
                    'We are a fully integrated strategy and creativity agency of multi-skilled and curious specialists.',
                    'theRef',
                    22
                  ),
                ]"
                
                :shuffle="false"
                initial-action="typing"
                :pre-type-delay="70"
                :type-delay="70"
                :pre-erase-delay="2000"
                :erase-delay="10"
                erase-style="backspace"
                :erase-on-complete="false"
                caret-animation="blink"
              ></vue-typer>
            </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  //  data: function () {
  //       return {
  //         startTypers: false,
  //       };
  //   },
  //   mounted() {
  //     setTimeout(() => {
  //       this.startTypers = true;
  //     }, 150);
  //   },
  //   methods:{
  //     formatText(text, ref, textSize = 22) {
  //         let maxChars = Math.floor(this.$refs[ref].clientWidth / textSize);
  //         let words = text.split(" ");
  //         let breaked = "";
  //         let currentCount = 0;
  //         words.forEach((word) => {
  //           currentCount += word.length;
  //           currentCount += 1;
  //           if (currentCount >= maxChars) {
  //             currentCount = word.length;
  //             breaked = `${breaked}\n${word} `;
  //           } else {
  //             breaked = `${breaked}${word} `;
  //           }
  //         });
  //         return breaked;
  //       },
  //   }
}
</script>

<style scoped>
.section-style{
  background-image: url("../assets/bc-image.jpg");
  height: 100vh;
  object-fit: cover;
  background-repeat: no-repeat;
}
.container-style{
  padding: 5rem 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

img {
width: 100%;

}
.row-typer{
  flex-grow: 1;
  align-items: center;
  height: 100%;
}
.contact-btn{
  height: 50px;
  border-radius: 10px;
  color: white;
  border:2px solid white;
  display: flex;
  align-items: center;
}
.contact-btn:hover{
  color: black;
  background-color: white;
}
.slogan{
  color: white;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}
.vue-typer{
  font-weight: bold !important;
  font-size: 40px !important;
}
>>>.vue-typer .custom.char.typed {
  color: white;
}
.vue-typer .custom.char.selected {
  color: white;
}
.vue-typer .custom.caret {
  animation: rocking 1s ease-in-out 0s infinite;
}
@media (max-width: 767px) {
  .slogan{
    font-size: 30px;
}
}
</style>
