<template>
  <section class="section-style">
    <div class="container" id="contact">
        <div class="row text-center">
            <br>
            <span class="span-title">Address:</span><span class="span-content">R. António Nicolau D'Almeida 45 Escritório 4.12, 4100-320 Porto</span> 
            <br>
            <br>
            <span class="span-title">Email:</span><span class="span-content"> geral@longomai.pt</span>

        </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.section-style{
    background-color: #333;
    padding: 50px 0;
}
.span-title{
    color: white;
    font-size: 20px;
    font-weight: bold;
}
.span-content{
    color: white;
    font-size: 15px;
}
</style>