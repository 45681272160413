<template>
  <section>
    <div class="container container-style">
        <div class="row">
          <div class="title-div">
            <h2>WE ARE EXPERTS</h2>
            <h5>In finding the best solutions for your business.</h5>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 text-center py-4">
            <img class="icons" src="../assets/web-Icon.svg" alt="">
            <br>
            <span class="span-title">WEB & APP & SOFTWARE DEVELOPMENT</span>
            <br>
            <span class="span-desc">Our user-centered thinking creates a powerful presence in the vast world wide web.</span>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 text-center py-4">
            <img class="icons" src="../assets/insta-icon.svg" alt="">
            <br>
            <span class="span-title">BRANDING, CONTENT & DIGITAL MEDIA</span>
            <br>
            <span class="span-desc">We bring dreams to life by developing brands with 360 personality and digital strategies</span>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 text-center py-4">
            <img class="icons" src="../assets/doc-Icon.svg" alt="">
            <br>
            <span class="span-title">AI, MARKETING & STRATEGY</span>
            <br>
            <span class="span-desc">Ranging from innovation and product-related processes to marketing and communication implementation.</span>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 text-center py-4">
            <img class="icons" src="../assets/insta-icon.svg" alt="">
            <br>
            <span class="span-title">VIDEO & PHOTOGRAPHY</span>
            <br>
            <span class="span-desc">We transport people in time and space, fruit of our strong and different perspectives.</span>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.container-style{
  padding: 3rem;
}
.title-div{
  text-align: center;
  padding: 3rem 0;
}
.icons{
  width: 100px;
  height: 100px;
}
.span-title{
  font-size: 20px;
  font-weight: bold;
}
.span-desc{
  font-size: 15px;
}
</style>